<template>
  <v-dialog v-model="toggle" max-width="500" persistent>
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="loading"
          :isDelete="true"
          :title="account.name"
          @onClose="closeModal()"
        />

        <v-row style="justify-content: center;">
          <v-col md="8" style="text-align: center;">
            <h4>{{'Tem certeza que deseja excluir a conta ' + account.name + ' (' + tipoDeConta() + ')?'}}</h4>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="secondary"
              style="width: 100%;"
              :loading="loading"
              @click="excluirConta()">
              Confirmar
            </v-btn>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="error"
              style="width: 100%;"
              :loading="loading"
              @click="closeModal()">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions } from 'vuex'
import { accountTypesEnum } from '@/utils/tempUtils'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

const namespaceStore = 'organizzeFinanceiroContas'

export default {
  name: 'OrganizzeFinanceiroContasModalExcluir',

  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header')
  },

  data: (() => ({
    toggle: false,
    account: {
      id: null,
      name: '',
      type: null
    },
    loading: false
  })),

  mounted () {
    const self = this

    Events.$on('modalExcluir::open', data => {
      self.toggle = true
      self.account = {
        id: data.id,
        name: data.name,
        type: data.type
      }
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['deleteAccount']),

    closeModal () {
      const self = this
      self.toggle = false
      self.account = {
        id: null,
        name: '',
        type: null
      }
    },

    excluirConta () {
      const self = this

      self.loading = true
      self.deleteAccount(self.account).then(() => {
        successSnackbar('Conta excluída com sucesso.')
        Events.$emit('index::getAccounts')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.loading = false
      })
    },

    tipoDeConta () {
      const self = this
      return accountTypesEnum()[self.account.type]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }
</style>